import { Box, Container } from '@mui/material'
import type { Breakpoint } from '@mui/system'
import { mobileSideSpace, spacing } from '@orus.eu/pharaoh'
import { memo, type FunctionComponent, type ReactNode } from 'react'
import { useLargeScreen } from '../../lib/use-large-screen'
import { SmoothScrollBar } from '../atoms/smooth-scrollbar'

type ActionPageLayoutProps = {
  body: ReactNode
  actions: ReactNode
  maxWidth: Breakpoint
}

export const ActionPageLayout: FunctionComponent<ActionPageLayoutProps> = memo(function ActionPageLayout({
  body,
  actions,
  maxWidth,
}) {
  const largeScreen = useLargeScreen()
  if (largeScreen) {
    return (
      <SmoothScrollBar style={{ width: '100%', height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'spaceBetween',
            gap: spacing[80],
            paddingTop: spacing[100],
            paddingBottom: spacing[100],
          }}
        >
          <Container maxWidth={maxWidth}>
            {body}
            <Box sx={{ display: 'flex', marginTop: spacing[80], gap: spacing[70], justifyContent: 'space-between' }}>
              {actions}
            </Box>
          </Container>
        </Box>
      </SmoothScrollBar>
    )
  }

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: spacing[70],
        paddingLeft: mobileSideSpace,
        paddingRight: mobileSideSpace,
        paddingTop: spacing[70],
        paddingBottom: spacing[70],
        overflow: 'auto',
      }}
    >
      <Box>{body}</Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          gap: spacing[60],
        }}
      >
        {actions}
      </Box>
    </Box>
  )
})
